import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Dashboard from "./components/Pages/Dashboard";
import Login from "./components/Pages/Login";
import Users from "./components/Pages/Users";
import DeletedUsers from "./components/Pages/DeletedUsers";
import { Provider } from "./Util/Context/Context";
import Clients from "./components/Pages/Clients";
import UserDetail from "./components/Pages/Details/UserDetail";
import Roleselector from "./components/Pages/Roleselector";
import { Provider as AlertProvider, transitions, positions } from "react-alert";
//@ts-ignore
import AlertTemplate from "react-alert-template-basic";
import ClientDetail from "./components/Pages/Details/ClientDetail";
import Plants from "./components/Pages/Plants";
import RegisterClient from "./components/Pages/Register/RegisterClient";
import Portfolios from "./components/Pages/Portfolios";
import PlantDetail from "./components/Pages/Details/PlantDetail";
import RegisterPlant from "./components/Pages/Register/RegisterPlant";
import ProductLines from "./components/Pages/ProductLines";
import RegisterPortfolio from "./components/Pages/Register/RegisterPortfolio";
import PortfolioDetail from "./components/Pages/Details/PortfolioDetails";
import RegisterProductLine from "./components/Pages/Register/RegisterProductLine";

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-client";
import { from } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import ProductLineDetail from "./components/Pages/Details/ProductLineDetail";
import ProductStyles from "./components/Pages/ProductStyles";
import ProductStyleDetail from "./components/Pages/Details/ProductStyleDetail";
import RegisterProductStyle from "./components/Pages/Register/RegisterProductStyle";
import Products from "./components/Pages/Products";
import ProductDetail from "./components/Pages/Details/ProductDetail";
import RegisterProduct from "./components/Pages/Register/RegisterProduct";
import Applications from "./components/Pages/Applications";
import RegisterApplication from "./components/Pages/Register/RegisterApplication";
import ApplicationDetail from "./components/Pages/Details/ApplicationDetail";
import InstalledProducts from "./components/Pages/InstalledProducts";
import InstalledProductDetail from "./components/Pages/Details/InstalledProductDetail";
import Distributors from "./components/Pages/Distributors";
import DistributorDetail from "./components/Pages/Details/DistributorDetail";
import RegisterDistributor from "./components/Pages/Register/RegisterDistributor";
import TrainingRequests from "./components/Pages/TrainingRequests";
import TrainingRequestDetail from "./components/Pages/Details/TrainingRequestDetail";
import RegisterResource from "./components/Pages/Register/RegisterResource";
import ResourceDetail from "./components/Pages/Details/ResourceDetail";
import Profile from "./components/Pages/Profile";
import PasswordForgot from "./components/Pages/PasswordForgot";
import PasswordRecovery from "./components/Pages/PasswordRecovery";
import Responsibles from "./components/Pages/Responsibles";
import HomePage from "./components/Pages/HomePage";
import ResponsibleDetail from "./components/Pages/Details/ResponsibleDetail";
import TrainingRequestReasons from "./components/Pages/TrainingRequestReasons";
import TrainingRequestReasonDetail from "./components/Pages/Details/TrainingRequestReasonDetail";
import RegisterTrainingRequestReason from "./components/Pages/Register/RegisterTrainingRequestReason";
import { ChatInterface } from "./components/Pages/Chat/ChatInterface";
import { ChatContextProvider } from "./components/Pages/Chat/Context/ChatContext";
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import Notificationprovider from "./components/Pages/Chat/Context/NotificationProvider";
import { Notification } from "./components/Pages/Chat/Components";
import RegisterCodes from "./components/Pages/RegisterCodes";
import SuccessCases from "./components/Pages/SuccessCases";
import RegisterSuccessCase from "./components/Pages/Register/RegisterSuccessCase";
import Industries from "./components/Pages/Industries";
import RegisterIndustry from "./components/Pages/Register/RegisterIndustry";
import IndustryDetail from "./components/Pages/Details/IndustryDetail";
import SuccessCaseDetail from "./components/Pages/Details/SuccessCaseDetail";
import TransactionsRequests from "./components/Pages/Transactions";

//end of imports
//@ts-ignore
console.log(process.env.REACT_APP_DEV_URL)
let env = process.env.REACT_APP_DEV_URL?.trim() || ''; //'http://3.88.72.55'
if (env) env = env + "/graphql";

// gets token in sessionStorage
const GetToken = () => {
  return sessionStorage.getItem("token");
};

//Sets the headers fot the apolloClient
//When a user logs In it will change state in app so now client should have the sessionStorage's token to send it as a header in each request
const authMiddleware = setContext(async (_, { headers, ...context }) => {
  const token = GetToken();
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `bearer ${token}` } : {}),
    },
    ...context,
  };
});

const httpLink = createUploadLink({ uri: env })

const wsLink = new WebSocketLink({
  uri: env.replace('http', 'ws'),
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = GetToken();
      return {
        ...(token ? { authorization: `bearer ${token}` } : {}),
      };
    },
    reconnectionAttempts: 5,
    connectionCallback: (error: Error[]) => {
      if (error) {
        console.log(error);
      }
    },
    inactivityTimeout: 1000
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

const cache = new InMemoryCache();

//creates the client with already declared properties
export const client = new ApolloClient({
  link: from([authMiddleware, splitLink]),
  cache: cache,
});


//Options to how it should behave the alert component
const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.FADE,
};

//Type of user which is logged
export type roletype = "ADMIN" | "SALESMAN" | "USER_CLIENT" | "DSO" | "ADVISER" | "AGENT";
export interface user {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: roletype;
  department?: string;
  function?: string;
  createdAt?: string;
  status?: string;
  plantPortfolios?: string[];
}

const App: React.FC<{}> = () => {
  //Here it's declared the state of the component which will be passed as the context for child components along the Panel
  //used for validating and rendering certain data in case user logged is a DSO and not an ADMIN
  const [DSO, setDSO] = useState<boolean>(false);
  const [user, setUser] = useState<user>({
    id: "",
    firstName: "",
    lastName: "",
    role: "ADMIN",
    email: "",
    phoneNumber: "",
  });

  const [logged, setLogged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  //at component mounted it gets teh token in sessionStorage
  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setLogged(true);
    }
  }, []);

  //If it's logged if sets loading to false
  useEffect(() => {
    setLoading(false);
  }, [logged]);

  return (
    //here we declare the apollo provider with the client declared at the beggining
    <ApolloProvider client={client}>
      {/* This is the contextAPI provider, which should manage global state in all the components, the context is declared in ./Util/Context/Context.ts */}
      <Provider
        value={{
          user: user,
          setUser: setUser,
          logged: logged,
          setLogged: setLogged,
          DSO: DSO,
          setDSO: setDSO,
        }}
      >
        <Notificationprovider>
          <ChatContextProvider>
            {/* Alert Provider to be used in all components, setting up the options declared above */}
            <AlertProvider {...options} template={AlertTemplate}>
              {/* If a token is retraived, it means we have a user in session */}
              {!loading && (
                <Router>
                  {/* If a user is logged ternary */}
                  {/* if a user logsOut in a child component it will cause to change context in App.tsx, triggering a re-rendering and it eventually will be redirected to limited routing */}
                  {logged ? (
                    //If the user is logged, if have access to all routing, in case it isn't it will be redirected to login with no chance of accessing any route inside the switch
                    <Dashboard>
                      {/* If the user logged is a DSO type user it will only have access to user's related routes */}
                      {user.role === "DSO" ? (
                        <Switch>
                          {window.history.pushState("", "", "/users")}
                          <Route exact path="/users" component={Users} />
                          <Route exact path="/profile" component={Profile} />
                          <Route exact path="/users/detail/:id" component={UserDetail} />
                          <Route path="*" component={Users} />
                          <Redirect to="/users" />
                        </Switch>
                      ) : (
                          // Otherwise it will gain access to full routing
                          <Switch>
                            <Route exact path="/chat" component={ChatInterface} />
                            <Route exact path="/users" component={Users} />
                            <Route exact path="/deletedusers" component={DeletedUsers} />
                            <Route exact path="/profile" component={Profile} />
                            <Route exact path="/users/detail/:id" component={UserDetail} />
                            <Route
                              exact
                              path="/clients/detail/:id"
                              component={ClientDetail}
                            />
                            <Route
                              exact
                              path="/plants/detail/:id"
                              component={PlantDetail}
                            />
                            <Route
                              exact
                              path="/productlines/detail/:id"
                              component={ProductLineDetail}
                            />
                            <Route
                              exact
                              path="/productstyles/detail/:id"
                              component={ProductStyleDetail}
                            />
                            <Route
                              exact
                              path="/products/detail/:id"
                              component={ProductDetail}
                            />
                            <Route
                              exact
                              path="/industries/detail/:id"
                              component={IndustryDetail}
                            />
                            <Route
                              exact
                              path="/successcases/detail/:id"
                              component={SuccessCaseDetail}
                            />
                            <Route
                              exact
                              path="/applications/detail/:id"
                              component={ApplicationDetail}
                            />
                            <Route
                              exact
                              path="/installedproducts/detail/:id"
                              component={InstalledProductDetail}
                            />
                            <Route
                              exact
                              path="/distributors/detail/:id"
                              component={DistributorDetail}
                            />
                            <Route
                              exact
                              path="/responsibles/detail/:id"
                              component={ResponsibleDetail}
                            />
                            <Route
                              exact
                              path="/trainingrequests/detail/:id"
                              component={TrainingRequestDetail}
                            />
                            <Route
                              exact
                              path="/reasons/detail/:id"
                              component={TrainingRequestReasonDetail}
                            />
                            <Route
                              exact
                              path="/:entity/detail/:id/resources/detail/:resourceid"
                              component={ResourceDetail}
                            />
                            <Route
                              exact
                              path="/portfolios/detail/:id"
                              component={PortfolioDetail}
                            />
                            <Route
                              exact
                              path="/registeruser"
                              component={Roleselector}
                            />
                            <Route
                              exact
                              path="/registerclient"
                              component={RegisterClient}
                            />
                            <Route
                              exact
                              path="/registerplant"
                              component={RegisterPlant}
                            />
                            <Route
                              exact
                              path="/registerportfolio"
                              component={RegisterPortfolio}
                            />
                            <Route
                              exact
                              path="/registerproductline"
                              component={RegisterProductLine}
                            />
                            <Route
                              exact
                              path="/registersuccesscase"
                              component={RegisterSuccessCase}
                            />
                            <Route
                              exact
                              path="/registerindustry"
                              component={RegisterIndustry}
                            />
                            <Route
                              exact
                              path="/registerproductstyle"
                              component={RegisterProductStyle}
                            />
                            <Route
                              exact
                              path="/registerproduct"
                              component={RegisterProduct}
                            />
                            <Route
                              exact
                              path="/registerapplication"
                              component={RegisterApplication}
                            />
                            <Route
                              exact
                              path="/registerdistributor"
                              component={RegisterDistributor}
                            />
                            <Route
                              exact
                              path="/registerreason"
                              component={RegisterTrainingRequestReason}
                            />
                            <Route
                              exact
                              path="/:entity/detail/:id/addresource"
                              component={RegisterResource}
                            />
                            <Route exact path="/clients" component={Clients} />
                            <Route exact path="/portfolios" component={Portfolios} />
                            <Route exact path="/plants" component={Plants} />
                            <Route
                              exact
                              path="/productlines"
                              component={ProductLines}
                            />
                            <Route
                              exact
                              path="/successcases"
                              component={SuccessCases}
                            />
                            <Route
                              exact
                              path="/industries"
                              component={Industries}
                            />
                            <Route
                              exact
                              path="/productstyles"
                              component={ProductStyles}
                            />
                            <Route exact path="/products" component={Products} />
                            <Route
                              exact
                              path="/applications"
                              component={Applications}
                            />
                            <Route
                              exact
                              path="/installedproducts"
                              component={InstalledProducts}
                            />
                            <Route
                              exact
                              path="/distributors"
                              component={Distributors}
                            />
                            <Route
                              exact
                              path="/trainingrequests"
                              component={TrainingRequests}
                            />
                            <Route
                              exact
                              path="/responsibles"
                              component={Responsibles}
                            />
                            <Route
                              exact
                              path="/reasons"
                              component={TrainingRequestReasons}
                            />
                            <Route
                              exact
                              path="/registercodes"
                              component={RegisterCodes}
                            />
                            <Route
                              exact
                              path="/login"
                              component={HomePage}
                            />
                            <Route
                              exact
                              path="/transactionrequests"
                              component={TransactionsRequests}
                            />
                            <Route exact path="/" component={HomePage} />
                          </Switch>
                        )}
                    </Dashboard>
                  ) : (
                      // In case the user is not logged it wont gat access to all routes, this is a matter of security, making routes "Private" to reduce non authorized access to data
                      <>
                        <Route
                          exact
                          path="/forgotpassword"
                          component={PasswordForgot}
                        />
                        <Route
                          exact
                          path="/password-recovery/:recoveryCode"
                          component={PasswordRecovery}
                        />
                        <Route path="/*" component={Login} />
                        {/* <Redirect to="/login" /> */}
                      </>
                    )}
                </Router>
              )}
              <Notification />
            </AlertProvider>
          </ChatContextProvider>
        </Notificationprovider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
