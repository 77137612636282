import gql from "graphql-tag";
export const getUsers = gql`
  query($filter: UserFilter) {
    users(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        role
        firstName
        lastName
        phoneNumber
        email
        status
        company
      }
    }
  }
`;

export const getDeletedUsers = gql`
  query($filter: UserFilter) {
    users(filter: $filter, getInactive: true) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        role
        firstName
        lastName
        phoneNumber
        email
      }
    }
  }
`;
export const getUserLogged = gql`
  query {
    user {
      id
      firstName
      lastName
      email
      phoneNumber
      department
      function
      createdAt
      role
      status
    }
  }
`;

export const getUserById = gql`
  query findusr($id: Float!) {
    user(where: { id: $id }) {
      id
      role
      firstName
      lastName
      email
      phoneNumber
      department
      function
      plant{
        id
        name
      }
      createdAt
      status
      plantPortfolios {
        id
        name
        plants {
          id
          name
        }
        salesman {
          id
          firstName
          lastName
        }
      }
      salesmanDistributors{
        id
        name
        address
        phoneNumber
      }
      distributor {
        id
        name
      }
    }
  }
`;

export const getRegisterCodes = gql`
  query($filter: RegisterCodeFilter, $orderBy: OrderByInput){
    registerCodes(filter: $filter, orderBy: $orderBy){
      pagination{
        currentPage
        totalPages
        limit
      }
      items{
        id
        createdAt
        usedAt
        code
        user{
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const getClients = gql`
  query($filter: ClientFilter) {
    clients(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        address
        contactNumber
        status
        plants {
          id
          name
          status
        }
      }
    }
  }
`;

export const getClientById = gql`
  query clientbyId($id: Float!) {
    client(where: { id: $id }) {
      id
      name
      address
      contactNumber
      status
      plants {
        id
        name
        address
        contactNumber
        status
      }
    }
  }
`;

export const getPlants = gql`
  query($filter: PlantFilter) {
    plants(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        address
        contactNumber
        status
        client {
          id
          name
        }
        plantPortfolio {
          id
          name
        }
        registeredProducts {
          id
          createdAt
          location
          distributor
          application {
            id
            name
          }
          createdBy{
            id
            firstName
            lastName
          }
          productStyle {
            id
            name
            productLine{
              id
              name
            }
          }
          product {
            id
            code
          }
          identifier
          internalCode
        }
      }
    }
  }
`;


export const getIndustry = gql`
  query($id: Float!) {
    industry(where: { id: $id }) {
      id
    	name
      image
      successCases{
        id
        name
      } 
    }
  }
`;

export const getIndustries = gql`
  query($filter: IndustryFilter) {
    industries(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
      }
    }
  }
`;

export const getSuccessCase = gql`
  query($id: Float!) {
    successCase(where: { id: $id }) {
      id
    	name
    	status
    	description
      image
      industry{
        id
        name
      }
    }
  }
`;

export const getSuccessCases = gql`
  query($filter: SuccessCaseFilter) {
    successCases(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        description
        industry{
          id
          name
        }
      }
    }
  }
`;

export const getResourcesByOwner = gql`
  query resourceByOwner($owner: String!, $itemId: Float!){
  	resourceByOwner(owner: $owner, itemId: $itemId){
      id
      name
      type
      path
      productLine{
        name
        id
      }
      successCase{
        name
        id
      }
  	}
  }
`;

export const getPortfolios = gql`
  query($filter: PlantPortfolioFilter) {
    portfolios(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        salesman {
          id
          firstName
          lastName
        }
        plants {
          id
          name
          client {
            id
            name
          }
          contactNumber
          address
        }
      }
    }
  }
`;

export const getPlantById = gql`
  query getPlantById($id: Float!) {
    plant(where: { id: $id }) {
      id
      name
      contactNumber
      address
      status
      plantPortfolio {
        id
        name
      }
      client {
        name
        id
      }
      users {
        firstName
        lastName
        id
        email
        phoneNumber
      }
      responsibles {
        id
        firstName
        lastName
        email
        phoneNumber
      }
      plantPortfolio {
        name
        id
      }
    }
  }
`;

export const getProductLines = gql`
  query($filter: ProductLineFilter, $orderBy: OrderByInput) {
    productLines(filter: $filter, orderBy: $orderBy) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        description
        image
        resources {
          id
          name
          description
          path
          type
          is_public
          image
        }
        productStyles{
          name
          id
        }
      }
    }
  }
`;

export const getPortfolioById = gql`
  query getPortfoliobyId($id: Float!) {
    portfolio(where: { id: $id }) {
      id
      name
      plants {
        id
        name
        address
        contactNumber
        client {
          name
          id
        }
      }
      distributors{
        id
        name
        address
        phoneNumber
      }
      salesman {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getProductStyles = gql`
  query($filter: ProductStyleFilter) {
    productStyles(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        description
        image
        status
        productLine {
          id
          name
        }
        products {
          id
          code
          description
        }
      }
    }
  }
`;

export const getProductLineById = gql`
  query($id: Float!) {
    productLine(where: { id: $id }) {
      id
      name
      description
      image
      status
      needs_fluid
      needs_pH
      needs_temperature
      needs_pressure
      needs_RPM
      needs_axisDiameter
      needs_Norm
      needs_valveType
      needs_bombBrand
      needs_textileType
      productStyles {
        id
        name
        description
        image
      }
      resources {
        id
        name
        type
        image
        path
        position
        is_public
      }
    }
  }
`;

export const getProductStyleById = gql`
  query($id: Float!) {
    productStyle(where: { id: $id }) {
      id
      name
      description
      image
      status
      productLine {
        id
        name
      }
      products {
        id
        description
        code
      }
      registeredProducts {
        id
      }
    }
  }
`;

export const getProducts = gql`
  query($filter: ProductFilter) {
    products(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        description
        code
        image
        productStyle {
          id
          name
        }
        createdAt
        registeredProducts {
          id
          createdAt
        }
      }
    }
  }
`;

export const getProductById = gql`
  query getProductById($id: Float!) {
    product(where: { id: $id }) {
      id
      description
      code
      image
      createdAt
      status
      productStyle {
        id
        name
      }
      registeredProducts {
        id
        createdAt
      }
    }
  }
`;

export const getApplications = gql`
  query($filter: ApplicationFilter, $orderBy: OrderByInput) {
    applications(filter: $filter, orderBy: $orderBy) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        description
      }
    }
  }
`;

export const getApplicationById = gql`
  query($id: Float!) {
    application(where: { id: $id }) {
      id
      name
      description
      createdAt
      createdBy {
        id
      }
      status
      
    }
  }
`;

export const getInstalledProductById = gql`
  query($id: Float!) {
    registeredProduct(where: { id: $id }) {
      id
      createdAt
      status
      location
      image
      comments
      estimatedChange
      comments
      trackingDate
      installedAt
      internalCode
      product {
        id
        code
      }
      user {
        id
      }
      responsible{
        id
      }
      application {
        id
        name
      }
      plant {
        id
        name
        client {
          id
          name
        }
        status
      }
    }
  }
`;

export const getDistributors = gql`
  query($filter: DistributorFilter) {
    distributors(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        name
        address
        phoneNumber
        users {
          id
          firstName
          lastName
          role
          department
          function
          email
        }
        plantPortfolio{
          id
          name
          salesman{
            id
            firstName
            lastName
          }
        }
        parentDistributor{
          id
          name
        }
        salesmanDirector{
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const getDistributorById = gql`
  query($id: Float!) {
    distributor(where: { id: $id }) {
      id
      name
      address
      phoneNumber
      status
      createdBy {
        id
      }
      users {
        id
        role
        firstName
        lastName
        email
        phoneNumber
      }
      plantPortfolio{
        id
        name
        salesman{
          id
          firstName
          lastName
        }
      }
      parentDistributor{
        id
        name
        address
        phoneNumber
      }
      childDistributors{
        id
        name
        address
        phoneNumber
      }
      salesmanDirector{
        id
        firstName
        lastName
      }
    }
  }
`;

export const getTrainingRequests = gql`
  query($filter: TrainingRequestFilter) {
    trainingRequests(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        type
        status
        notes
        proposedDate
        user {
          id
          firstName
          lastName
          plant {
            id
            name
            client {
              id
              name
            }
          }
        }
        productLines {
          id
          name
        }
      }
    }
  }
`;

export const getTrainingRequestById = gql`
  query($id: Float!) {
    trainingRequest(where: { id: $id }) {
      id
      notes
      createdAt
      type
      status
      proposedDate
      user {
        id
        firstName
        lastName
      }
      reason {
        id
        reason
      }
      productLines {
        id
        name
        description
      }
    }
  }
`;

export const getResourceById = gql`
  query($id: Float!) {
    resource(where: { id: $id }) {
      id
      name
      description
      path
      type
      image
      is_public
      productLine {
        id
        name
      }
      successCase {
        id
        name
      }
    }
  }
`;

export const validatePasswordRecoveryCode = gql`
  query validatePasswordRecoveryToken($data: ValidatePasswordRecoveryInput!) {
    validatePasswordRecoveryToken(data: $data) {
      message
      code
    }
  }
`;

export const getResponsibles = gql`
  query($filter: ResponsibleFilter) {
    responsibles(filter: $filter) {
      pagination {
        currentPage
        totalPages
        limit
      }
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        plant {
          id
          name
          client {
            id
            name
          }
        }
      }
    }
  }
`;

export const getResponsibleById = gql`
  query getResponsible($id: Float!) {
    responsible(where: { id: $id }) {
      id
      firstName
      lastName
      email
      phoneNumber
      plant {
        id
        name
        client {
          id
          name
        }
      }
      status
      registeredProducts {
        id
        internalCode
        application {
          id
          name
        }
        product {
          id
          code
        }
      }
    }
  }
`;

export const getTrainingRequestReasons = gql`
  {
    trainingRequestReasons {
      id
      reason
      createdAt
      status
    }
  }
`;

export const getTrainingRequestReasonById = gql`
  query getTrainingRequestReasonById($id: Float!) {
    trainingRequestReason(where: { id: $id }) {
      id
      reason
      createdAt
      createdBy {
        id
      }
      status
    }
  }
`;

export const recordsAmount = gql`
  {
    recordsAmount {
      users
      products
      installedProd
      clients
      plants
      support
    }
  }
`;

export const getInstalledProducts = gql`
  query($page: PaginationInput!) {
    registeredProducts(page: $page, filters:{filterBy:null}) {
      id
      createdAt
      updatedAt
      status
      location
      image
      comments
      installedAt
      estimatedChange
      trackingDate
      trackingFreq
      internalCode
      distributor
      identifier
      application {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      plant {
        id
        name
        client{
          id
          name
        }
      }
      createdBy{
        id
        firstName
        lastName
      }
      product{
        code
      }
      productStyle{
        id
        productLine{
          id
          name
        }
      }
    }
  }
`;

export const getInstalledProductsWithPagination = gql`
  query($filter: RegisteredProductFilter){
    registeredProductsWithPagination(filter: $filter){
      pagination {
        currentPage
        totalPages
        limit
      }
      items{
        id
        createdBy{
          id
          firstName
          lastName
        }
        product{
          id
          code
        }
        internalCode
        plant{
          id
          name
          plantPortfolio{
            salesman{
              id
              firstName
              lastName
            }
          }
          client{
            id
            name
          }
        }
        application{
          id
          name
        }
        identifier
        productStyle{
          id
          name
          productLine{
            id
            name
          }
        }
        user{
          id
          firstName
          lastName
        }
        responsible{
          id
          firstName
          lastName
        }
        fluidType
        installedAt
        estimatedChange
        distributor
        previousReg
        createdAt
        replacedBy{
          id,
					createdAt
          installedAt
        }
        lifeTimeDays
        replaced{
          id
          createdAt
          installedAt
        }
        createdInBehalfOf{
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const getChats = gql`
query{
    getChats{
        id
        createdAt
        closedAt
        openedAt
        email
        status
        first_name
        last_name
        company
        phone
    }
}
`;

export const openChat = gql`
mutation openChat($chat_id: Float!) {
  openChat(chat_id: $chat_id) {
    id
    last_name
  }
} 
`;


export const closeChats = gql`
mutation closeChat($chat_id: Float!){
    closeChat(chat_id: $chat_id){
        id
        createdAt
        closedAt
        openedAt
        status
}}
`;


export const CHAT_SUBSCRIPTION = gql`
subscription sendMessage($email: String! $chat_id: Float!) {
        sendMessage(email:$email, chat_id: $chat_id) {
                id
                createdAt
                sentBy
                message
                chat {
                  id
                  status
                }
            }
        }
`

export const getSingleChat = gql`
query getSingleChat($chat_id: Float!){
    getSingleChat(chat_id:$chat_id){
        messages{
            id
            message
            createdAt
            sentBy
        }
    }
}
`

export const sendMessages = gql`
mutation sendMessageAsAdmin($message: String! $email: String! $chat_id: Float!){
    sendMessageAsAdmin(data:{
        message: $message
        email: $email
        chat_id: $chat_id
    }){
        id
}}
`

export const notificationSubscription = gql`
subscription {
  adminNotifications {
    id
    type
    status
    chat{
        id
        createdAt
        closedAt
        openedAt
        email
        status
        first_name
        last_name
        company
        phone
    }
  }
}
`

export const downloadInstalledProductsReport = gql`
  query{
    downloadInstalledProductsReport{
      link
    }
  }
`
export const downloadUsersReport = gql`
  query{
    downloadUsersReport{
      link
    }
  }
`

export const downloadTransactionsReport = gql`
  query{
    downloadTransactionsReport{
      link
    }
  }
`

export const getAllTransactions = gql`
  query ($filter: FilterBase) {
    allTransactions(filter: $filter) {
      items {
        id
        type
        amount
        createdAt
        updatedAt
        status
        cancellationReason
        canceledAt
        invoiceLink
        productStyle {
          id
          name
			  }
        plant {
          id
          name
          client {
            id
            name
          }
			}
        createdBy {
          firstName
          lastName
        }
      }
      pagination {
        currentPage
        limit
        totalPages
      }
    }
  }
  `
